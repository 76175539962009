import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/uireact/uireact/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Props } from 'docz';
import * as packageJson from '../package.json';
import Playground from '../../../src/Playground';
import { UiExpandoCard } from '../src/';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1 {...{
      "id": "uiexpandocard"
    }}>{`UiExpandoCard`}</h1>
    <sup>
  <a href="https://github.com/inavac182/ui-react/blob/main/packages/expando/" target="_blank">
    v{packageJson.version}
  </a>
    </sup>
    <blockquote>
      <p parentName="blockquote">{`This component renders a card with a heading that when expanded reveals some content inside the card.`}</p>
    </blockquote>
    <h2 {...{
      "id": "installation"
    }}>{`Installation`}</h2>
    <blockquote>
      <p parentName="blockquote">{`npm i @uireact/foundation @uireact/icons @uireact/expando`}</p>
    </blockquote>
    <h2 {...{
      "id": "just-a-expando"
    }}>{`Just a expando`}</h2>
    <Playground __position={1} __code={'<UiExpandoCard expandLabel=\"Expand me\" collapseLabel=\"Collapse me\">\n  <span>This is some content</span>\n</UiExpandoCard>'} __scope={{
      props,
      DefaultLayout,
      Props,
      packageJson,
      Playground,
      UiExpandoCard,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UiExpandoCard expandLabel="Expand me" collapseLabel="Collapse me" mdxType="UiExpandoCard">
    <span>This is some content</span>
  </UiExpandoCard>
    </Playground>
    <h2 {...{
      "id": "expando-with-custom-category-and-inverse-heading-coloration"
    }}>{`Expando with custom category and inverse heading coloration`}</h2>
    <Playground __position={2} __code={'<UiExpandoCard\n  expandLabel=\"Expand me\"\n  collapseLabel=\"Collapse me\"\n  category=\"tertiary\"\n  headingInverseColoration\n>\n  <span>This is some content</span>\n</UiExpandoCard>'} __scope={{
      props,
      DefaultLayout,
      Props,
      packageJson,
      Playground,
      UiExpandoCard,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UiExpandoCard expandLabel="Expand me" collapseLabel="Collapse me" category="tertiary" headingInverseColoration mdxType="UiExpandoCard">
    <span>This is some content</span>
  </UiExpandoCard>
    </Playground>
    <h2 {...{
      "id": "expando-initiated-expanded"
    }}>{`Expando initiated expanded`}</h2>
    <Playground __position={3} __code={'<UiExpandoCard expandLabel=\"Expand me\" collapseLabel=\"Collapse me\" expanded>\n  <span>This is some content</span>\n</UiExpandoCard>'} __scope={{
      props,
      DefaultLayout,
      Props,
      packageJson,
      Playground,
      UiExpandoCard,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UiExpandoCard expandLabel="Expand me" collapseLabel="Collapse me" expanded mdxType="UiExpandoCard">
    <span>This is some content</span>
  </UiExpandoCard>
    </Playground>
    <h3 {...{
      "id": "props"
    }}>{`Props`}</h3>
    <Props of={UiExpandoCard} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      